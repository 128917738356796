export default {
    getTextExport (inputs, formulations) {
        const curDate = new Date()
        const dateText = `${curDate.getMonth() + 1}-${curDate.getDate()}-${curDate.getFullYear()}`
        var exportText = `${process.env.VUE_APP_NAME} Prediction results - ${dateText}\n\n--Input Parameters\n`
            + `\tMaximum Dissolution:\t ${inputs.aSquared} %\n`
            + `\tApproximate Midopint:\t ${inputs.bSquared} Hrs\n`
            + `\tDissolution Rate:\t ${Math.floor((inputs.expC * 50) * 100) / 100} %\n`
            // + `\tFlexibility:\t\t ${inputs.flex} %\n`
            // + `\tExhaustive Search:\t ${inputs.fullSet ? 'YES' : 'NO'}\n`
            + '\n--Coating Formulations for Extended release\n'
        formulations.forEach((rec, i) => {
            exportText += `${i + 1}.\n\tCoating polymer\t\t Blend ratio (by wt.)\n`
            exportText += `\t${rec.CE}\t\t ${Math.floor(rec.CE_perc * 100) / 100} %\n`
            exportText += `\tC-A-P\t\t\t ${Math.floor((100 - rec.CE_perc) * 100) / 100} %\n\n`
            exportText += `\tCoating weight gain:\t ${rec.Coating_perc} %\n\n`
        })
        
        const timestamp = `${curDate.getHours()}_${curDate.getMinutes()}_${curDate.getSeconds()}`

        return {
            url: `data:text/plain,${encodeURI(exportText)}`,
            filename: `${process.env.VUE_APP_NAME}-Export ${dateText} ${timestamp}`
        }
    },
    getCSVExportUrl (inputs, formulations) {
        var exportText = 'Maximum Dissolution (%),(input) Approximate Midpoint (hrs),(input) Dissolution Rate (%),(input) Flexibility (%),(input) Exhaustive Search'
            + ',Ester,Ester Blend ratio (by wt.)(%),C-A-P Blend Ratio (by wt.)(%),Coating weight gain (%)\n'
        
        formulations.forEach(rec => {
            exportText += `${inputs.aSquared},${inputs.bSquared},${inputs.expC},${inputs.flex},${inputs.fullSet ? 'YES' : 'NO'},`
                + `${rec.CE},${rec.CE_perc},${100 - rec.CE_perc},${rec.Coating_perc}\n`
        })

        return `data:text/csv,${encodeURI(exportText)}` 
    },
}
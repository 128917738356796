import axios from 'axios'
const OUTBOUND_PENALTY = 0.0316

export default {
  getRecommendations: async function (aSquared, bSquared, expC, flex, recs, fullSet) {
    const urlBase = process.env.VUE_APP_PREDICTION_API_BASE
    const key = process.env.VUE_APP_PREDICTION_API_KEY
    
    const targets = { 'a_squared': aSquared, 'b_squared': bSquared * 60, 'exp_c': expC }
    const data = { 'targets': targets, 'flex': flex, 'n_recs': recs, 'full_result_set': fullSet, 'out_bound_penalty': OUTBOUND_PENALTY}
    const url = `${urlBase}/recommend?code=${key}`
    const response = await axios.post(url, data, { crossDomain: true })
    return response
  },
  getPrediction: async function (CE, CE_perc, Coating_perc) {
    const urlBase = process.env.VUE_APP_PREDICTION_API_BASE
    const key = process.env.VUE_APP_PREDICTION_API_KEY

    const data = { 'CE': CE, 'CE_perc': CE_perc, 'Coating_perc': Coating_perc}
    const url = `${urlBase}/prediction?code=${key}`
    const response = await axios.post(url, data, { crossDomain: true })
    return response
  },
}
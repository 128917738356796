<template>
  <div>
    <div
      class="input-container"
      v-if="this.currentFormulation.params"
    >
      <v-row justify="center">
        <v-col xs="12" sm="12" md="12" lg="auto" class="px-12 input-vert-center">
          <div style="display: flex; justify-content: center;">
            <span class="text-h4">
              {{ $t('ResultsInputTitle') }}
            </span>
          </div>
        </v-col>
        <v-col 
          cols="auto"
          class="px-12"
        >
          <p
            class="input-param text-subtitle-1"
          >
            {{ $t('ResultsInputMax') }}
          </p>
          <p
            class="input-param text-h5 font-weight-bold"
          >
            {{ `${this.currentFormulation.params.aSquared}%` }}
          </p>
        </v-col>
        <v-col
          cols="auto"
          class="px-12 input-separated"
        >
          <p
            class="input-param text-subtitle-1"
          >
            {{ $t('ResultsInputMid') }}
          </p>
          <p
            class="input-param text-h5 font-weight-bold"
          >
            {{ `${this.currentFormulation.params.bSquared} ${this.$t('ResultsInputHour')}` }}
          </p>
        </v-col>
        <v-col 
          cols="auto"
          class="px-12 input-separated"
        >
          <p
            class="input-param text-subtitle-1"
          >
            {{ $t('ResultsInputSteepness') }}
          </p>
          <p
            class="input-param text-h5 font-weight-bold"
          >
            {{ `${Math.floor((this.currentFormulation.params.expC * 50) * 100) / 100}%` }}
          </p>
        </v-col>
        <!-- <v-col
          cols="2"
          class="input-separated"
        >
          <p
            class="input-param text-subtitle-1"
          >
            {{ $t('ResultsInputFlex') }}
          </p>
          <p
            class="input-param text-h5 font-weight-bold"
          >
            {{ `${this.currentFormulation.params.flex}%` }}
          </p>
        </v-col> -->
        <v-col xs="12" sm="12" md="12" lg="auto" class="pt-6 px-12">
          <!-- <v-row>
            <v-btn
              dark
              class="mb-5"
              color="var(--eastman-azure)"
              to="/coatings/predict"
            >
              {{ $t('ResultsNew') }}
            </v-btn>
          </v-row> -->
          <v-row justify="center">
            <v-dialog
              transition="dialog-bottom-transition"
              :max-width="editDialogWidth"
              max-height="80vh"
              v-model="editDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  large
                  style="font-size: 16px;"
                  v-bind="attrs"
                  v-on="on"
                  color="var(--eastman-azure)"
                  class="mt-6"
                >
                  {{ $t('ResultsEdit') }}
                </v-btn>
              </template>
              <v-card class="pa-8 py-10">
                <v-card-title class="text-h5 title-break">
                  {{ this.$t('PredictionParameterTitle') }}
                  <v-spacer />
                  <v-btn
                    text
                    icon
                    color="black"
                    @click="editDialog=false"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <div
                  class="px-4"
                  style="padding-top: 50px"
                >
                  <PredictionForm
                    :defaultA="this.currentFormulation.params.aSquared"
                    :defaultB="this.currentFormulation.params.bSquared"
                    :defaultC="this.currentFormulation.params.expC"
                    :defaultFlex="this.currentFormulation.params.flex"
                    :defaultFullSet="this.currentFormulation.params.fullSet"
                    :defaultNumRecs="this.currentFormulation.params.numRecs"
                    :dialogDisplay="true"
                    @predictComplete="onEditComplete"
                    @predictClose="editDialog=false"
                  />
                </div>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div
      class="results-container"
    >
      <!-- <v-row>
        <v-col cols="12">
          <span class="text-h2">
            {{ $t('ResultsTitle') }}
          </span>
        </v-col>
      </v-row> -->
      <v-row
        v-if="this.currentFormulation.recs[this.selectedRec].warningCode === 0"
      >
        <v-col cols="12">
          <v-alert
            border="left"
            colored-border
            color="var(--eastman-gold)"
            elevation="2"
            class="my-4"
          >
            <div style="display: flex; flex-direction: row;">
              <div style="display: flex; flex-direction: column; justify-content: center; margin-right: 1em;">
                <v-icon
                  color="var(--eastman-gold)"
                  x-large
                >
                  mdi-alert-circle
                </v-icon>
              </div>
              <div>
                <div class="font-weight-bold text-h6">{{ this.warning.heading }}</div>
                <div>{{ this.warning.body }}
                <a
                  :href="this.warning.linkTo"
                  target="_blank"
                >
                  {{ this.warning.linkText }}
                </a>
                |
                <router-link
                  :to="this.contactLink"
                >
                  Contact us
                </router-link>
                </div>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        justify="end"
        class="mb-3"
      >
        <v-col cols="auto">
          <v-btn
            outlined
            large
            style="font-size: 16px;"
            color="var(--eastman-azure)"
            @click="copyShareUrl"
          >
            {{ $t('ResultsShare') }}
            <v-icon class="ml-1">
              mdi-share
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            outlined
            large
            style="font-size: 16px;"
            color="var(--eastman-azure)"
            @click="onExportClick"
          >
            {{ $t('ResultsExport') }}
            <v-icon class="ml-1">
              mdi-download
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="5" xl="4" style="padding-left 0px;">
          <!-- Formulation card -->
          <v-card
            class="pa-8 mb-8"
          >
            <v-card-title>
              <span class="text-h4 font-weight-light title-break">
                {{ $t('ResultsTitle') }}
              </span>
            </v-card-title>
            <div class="px-4">
              <v-divider/>
              <!-- <div class="mt-6 mb-3">
                <span class="text-h5 font-weight-medium">
                  {{ $t('ResultsBlendTitle') }}
                </span>
              </div> -->
              <div class="recommendation-container mt-6">
                <span class="text-h5 font-weight-medium">
                  {{ $t('ResultsBlendTitle') }}
                </span>
                <div style="display: flex; flex-direction: column; justify-content: flex-end;">
                  <span class="text-body-1">
                    {{ $t('ResultsBlendRatioLabel') }}
                  </span>
                </div>
              </div>
              <div class="recommendation-container">
                <div
                  class="ester-clickable"
                  @click="openEsterUrl"
                >
                  <span
                    class="text-h5 font-weight-medium" 
                  >
                    {{ currentFormulation.recs[this.selectedRec].CE }} 
                  </span>
                  <v-icon color="var(--eastman-azure)" class="mb-2">mdi-launch</v-icon>
                </div>
                <span
                  class="text-h5" 
                >
                  {{ `${Math.floor(currentFormulation.recs[this.selectedRec].CE_perc * 100) / 100}%` }}
                </span>
              </div>
              <div class="recommendation-description">
                <span>
                  {{ esterDescriptions[this.currentFormulation.recs[this.selectedRec].CE] }}
                </span>
              </div>
              <v-divider/>
              <div class="recommendation-container">
                <div
                  class="ester-clickable"
                  @click="openCAPUrl"
                >
                  <span
                    class="text-h5 font-weight-medium" 
                  >
                    C-A-P
                  </span>
                  <v-icon color="var(--eastman-azure)" class="mb-2">mdi-launch</v-icon>
                </div>
                <span
                  class="text-h5"
                >
                  {{ `${Math.floor((100 - currentFormulation.recs[this.selectedRec].CE_perc) * 100) / 100}%` }}
                </span>
              </div>
              <div class="recommendation-description">
                <span>
                  Cellulose Acetate Phthalate
                </span>
              </div>
              <v-divider/>
              <div class="recommendation-container" style="margin-top: 1.5em; margin-bottom: 1.5em">
                <div>
                  <span
                    class="text-h5 font-weight-medium title-break"
                  >{{ $t('ResultsCoating') }}
                  </span>
                </div>
                <span
                  class="text-h5"
                >
                  {{ `${Math.floor(currentFormulation.recs[this.selectedRec].Coating_perc * 100) / 100}%` }}
                </span>
              </div>
            </div>
          </v-card>
          <!-- <v-card
            outlined
            elevation="0"
            class="pa-8"
          >
            <v-card-title class="text-h4 font-weight-light title-break">
              {{ $t('ResultsCoating') }}
            </v-card-title>
            <div class="recommendation-container pl-4">
              <span
                class="text-h5"
              >
                {{ `${Math.floor(currentFormulation.recs[this.selectedRec].Coating_perc * 100) / 100}%` }}
              </span>
            </div>
          </v-card> -->
        </v-col>
        <v-col xs="12" sm="12" md="12" lg="7" xl="8">
          <!-- results graph -->
          <v-card
            class="pa-10"
          >
            <coatings-chart
              :mainCurve="this.currentFormulation.recs[this.selectedRec].graph.pred"
              :secondaryCurve="this.currentFormulation.params"
              :upperCI="this.currentFormulation.recs[this.selectedRec].graph.ci_upper"
              :lowerCI="this.currentFormulation.recs[this.selectedRec].graph.ci_lower"
              :isResults="true"
              :hideToolbar="loading"
              :showCI="false"
              v-if="currentFormulation && currentFormulation.recs[selectedRec] && currentFormulation.recs[selectedRec].graph"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- Help Pop-up -->
    <v-card
      :class="'help-popup ' + helpClass"
    >
      <v-btn
          icon
          text
          class="help-close"
          color="black"
          @click="helpDismissed = true"
        >
          <v-icon >
            mdi-close
          </v-icon>
        </v-btn>
        <div class="mb-5">
          <span class="text-h4">Can we help you with anything?</span>
        </div>
        <v-btn
          dark
          large
          style="font-size: 16px;"
          color="var(--eastman-azure)"
          :to="this.contactLink"
        >
          Contact Us
        </v-btn>
    </v-card>
    <LoadingOverlay
      :showOverlay="loading"
    />
    <ErrorDialog
      :dialog="error"
      title="Error getting results"
      :message="errorMessage"
      @update:dialog="error = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import predictionAPI from '@/services/PredictionServices.js'
import exportAPI from '@/services/ExportServices.js'
import links from '@/services/LinkServices.js'

export default {
  Name: 'PredictionResults',
  props: {
    gen: {
      type: Boolean,
      required: false,
      default: false,
    },
    a: {
      type: Number,
      required: false,
      default: undefined,
    },
    b: {
      type: Number,
      required: false,
      default: undefined,
    },
    c: {
      type: Number,
      required: false,
      default: undefined,
    },
    flex: {
      type: Number,
      required: false,
      default: undefined,
    },
    fullSet: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    numRecs: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  components: {
    CoatingsChart: () => import('@/components/CoatingsChart.vue'),
    PredictionForm: () => import('@/components/coatings/PredictionForm.vue'),
    LoadingOverlay: () => import ('@/components/LoadingOverlay.vue'),
    ErrorDialog: () => import('@/components/ErrorDialog.vue'),
  },
  data: function () {
    return {
      selectedRec: 0,
      editDialog: false,
      esterUrls: {
        'CAB 171-15': 'https://www.eastman.com/Pages/ProductHome.aspx?product=71067846&pn=Cellulose+Acetate+Butyrate+-+CAB-171-15NF+(National+Formulary)',
        'CA 320S': 'https://www.eastman.com/Pages/ProductHome.aspx?product=71063628&pn=Cellulose+Acetate+-+CA-320S+NF%2fEP+(National+Formulary+and+European+Pharmacopoeia)',
        'CA 398-10': 'https://www.eastman.com/Pages/ProductHome.aspx?product=71051329&pn=Cellulose+Acetate+-+CA-398-10NF%2fEP+(National+Formulary+and+European+Pharmacopoeia)',
      },
      esterDescriptions: {
        'CAB 171-15': 'Cellulose Acetate Butyrate',
        'CA 320S': 'Cellulose Acetate',
        'CA 398-10': 'Cellulose Acetate',
      },
      CAPUrl: 'https://www.eastman.com/Pages/ProductHome.aspx?product=71014675&pn=Cellulose+Acetate+Phthalate+(C-A-P)+-+NF+(Pellets)',
      loading: false,
      fauxProgress: 0,
      interval: 0,
      error: false,
      errorMessage: '',
      helpInterval: 0,
      helpTotal: 0,
      helpDismissed: false,
    }
  },
  computed: {
    ...mapState(['currentFormulation', 'currentUserInfo']),
    warning: function () {
      return {
        heading: this.$t('PredictionOutsideRangeHead'),
        body: this.$t('PredictionOutsideRangeBody'),
        linkTo: process.env.VUE_APP_ABOUT_URL,
        linkText: this.$t('PredictionOutsideRangeLinkText'),
      }
    },
    helpClass: function () {
      const HELP_DELAY_SECONDS = 15
      var showHelp = !this.helpDismissed && this.helpTotal >= HELP_DELAY_SECONDS
      return showHelp ? 'help-popup-visible' : 'help-popup-hidden';
    },
    contactLink: function () {
      return links.getContactLink(this.currentUserInfo)
    },
    editDialogWidth: function () {
      var widthNum

      switch (this.$vuetify.breakpoint.name) {
        case 'xs': widthNum = 100; break;
        case 'sm': widthNum = 100; break;
        case 'md': widthNum = 90; break;
        case 'lg': widthNum = 90; break;
        default: widthNum = 70; break;
      }

      return `${widthNum}vw`
    }
  },
  mounted: async function () {
    // if prop to regen recommendations present, use props to get the formulation
    if (this.gen && this.a && this.b && this.c)
    {
      await this.genRecommendation()
    }

    // select the first recommendation (if there are multiple) and get the graph points if they aren't present
    this.selectedRec = 0
    if (this.currentFormulation
    && this.currentFormulation.recs[this.selectedRec]
    && !this.currentFormulation.recs[this.selectedRec].graph)
    {
      this.refreshRecGraph(this.selectedRec)
    }

    // reset user scroll to top of page
    window.scrollTo(0, 0)

    this.helpTotal = 0
    this.helpDismissed = false
    clearInterval(this.helpInterval)
    this.helpInterval = setInterval(() => {
      this.helpTotal += 1
      if (this.helpTotal > 15) {
        clearInterval(this.helpInterval)
      }
    }, 1000) 
  },
  methods: {
    openEsterUrl: function () {
      var url = this.esterUrls[this.currentFormulation.recs[this.selectedRec].CE]
      if (url) {
        window.open(url)
      }
    },
    openCAPUrl: function () {
      window.open(this.CAPUrl)
    },
    copyShareUrl: async function () {
      var shareUrl = `${process.env.VUE_APP_BASE_URL}/coatings/results?gen=true`
      shareUrl += `&a=${this.currentFormulation.params.aSquared}`
      shareUrl += `&b=${this.currentFormulation.params.bSquared}`
      shareUrl += `&c=${this.currentFormulation.params.expC}`
      shareUrl += `&flex=${this.currentFormulation.params.flex}`
      shareUrl += `&numRecs=${this.currentFormulation.params.numRecs}`
      shareUrl += `&fullSet=${this.currentFormulation.params.fullSet}`

      await navigator.clipboard.writeText(shareUrl)
      alert('sharable link has been copied to clipboard')
    },
    onEditComplete () {
      this.refreshRecGraph(0)
      this.editDialog = false
    },
    refreshRecGraph (recNum) {
      var rec = this.currentFormulation.recs[recNum]
      var graph = {}
      predictionAPI.getPrediction(rec.CE, rec.CE_perc, rec.Coating_perc)
        .then(response => {
          // console.log(response.data)
          graph.pred = {
            aSquared: (response.data.a_squared.pred < 100 ? response.data.a_squared.pred : 100),
            bSquared: response.data.b_squared.pred / 60,
            expC: response.data.exp_c.pred,
          }
          graph.ci_upper = {
            aSquared: (response.data.a_squared.ci_upper < 100 ? response.data.a_squared.ci_upper : 100),
            bSquared: response.data.b_squared.ci_upper / 60,
            expC: response.data.exp_c.ci_upper,
          }
          graph.ci_lower = {
            aSquared: (response.data.a_squared.ci_lower < 100 ? response.data.a_squared.ci_lower : 100),
            bSquared: response.data.b_squared.ci_lower / 60,
            expC: response.data.exp_c.ci_lower,
          }
          
          this.$store.dispatch('setRecGraph', {recNum: recNum, graph: graph})
        })
        .catch(error => {
          this.errorMessage = `Please try again or refresh this page in a moment. - ${String(error)}`
          this.error = true
          this.loading = false
        })
    },
    genRecommendation: async function () {
      this.loading = true
      this.startFauxLoad()

      this.$store.dispatch('setCurrentParams', {
        aSquared: this.a,
        bSquared: this.b,
        expC: this.c,
        flex: this.flex || 5.0,
        numRecs: this.numRecs || 1,
        fullSet: this.fullSet
      })

      try {
        var response = await predictionAPI.getRecommendations(this.a, this.b, this.c, this.flex || 5.0, this.numRecs || 1, this.fullSet)
        if (!response.error)
        {
          // console.log(response.data)

          var recs = []
          var maxRecs = response.data.resultsCount
          for (var i = 0; i < maxRecs; i++)
          {
            var warning = response.data.in_bound && response.data.in_bound[i]

            var rec = {
              CE: response.data.CE[i],
              CE_perc: response.data.CE_perc[i],
              Coating_perc: response.data.Coating_perc[i],
              warningCode: warning ?? 0,
              graph: null,
            }
            recs.push(rec)
          }
          
          this.$store.dispatch('setCurrentRecs', recs)
          this.loading = false
          clearInterval(this.interval)
        } else {
          this.errorMessage = response.error
          this.error = true
          this.loading = false
          clearInterval(this.interval)
        }
      }
      catch (error) {
        this.errorMessage = `error generating results. Please wait a moment, and refresh this page to try again.  - ${String(error)}`
        this.error = true
        this.loading = false
        clearInterval(this.interval)
      }
    },
    onExportClick() {
      var dlLink = document.createElement('a')

      var dl = exportAPI.getTextExport(this.currentFormulation.params, this.currentFormulation.recs)
      dlLink.href = dl.url
      dlLink.download = dl.filename
      dlLink.click()

      // textUrl = exportAPI.getCSVExportUrl(this.currentFormulation.params, this.currentFormulation.recs)
      // dlLink.href = textUrl
      // dlLink.download = `${process.env.VUE_APP_NAME}-export.csv`
      // dlLink.click()
    },
    startFauxLoad() {
      clearInterval(this.interval)
      var increment = 1.25

      this.interval = setInterval(() => {
        if (this.fauxProgress < 100) {
          this.fauxProgress += increment
        } else if (this.fauxProgress > 100) {
          this.fauxProgress = 100
        }
      }, 200)
    },
  },
}
</script>

<style>
.input-container {
  width: 100%;
  height: auto;
  padding: 33px;
  background-color: #0000001a;
}

.input-param {
  width: 100%;
}

.input-separated {
  border-style: solid;
  border-width: 0px 0px 0px 2px;
  border-color: #0000001a;
  /*padding-left: 3em !important;*/
}

.input-vert-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.results-container {
  padding: 4vh 10vw 10vh 10vw;
  background-color: #FAFAFA;
}

.recommendation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.recommendation-description {
  margin-bottom: 1em;
}

.ester-clickable {
  color: var(--eastman-azure);
  cursor: pointer;
}

.ester-clickable span:hover {
  text-decoration: underline;
}

.help-popup {
  position: fixed !important;
  right: 5vw;
  bottom: -10vh;
  transition: transform 1s !important;
  z-index: 500;
  min-height: 5vh;
  max-width: 25em !important;
  padding: 3em;
}

.help-popup-hidden {
  visibility: hidden;
}

.help-popup-visible {
  transform: translate(0px, -20vh);
}

.help-close {
  position: absolute !important;
  top: 2em;
  right: 2em;
}

</style>